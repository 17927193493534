<template>
  <div>
    <div class="px-5 py-9">
      <div></div>
      <div class="text-center">
        <img src="@/assets/logo.png" class="mx-auto" />
      </div>
    </div>
    <div class="py-3">
      <div class="mx-5 text-current text-left fontSize28 font-normal">
        Gerenciar fotos
      </div>
      <div
        class="mt-3 tracking-wide text-sm text-left mx-5 font-normal text-gray-400"
      >
        Verifique se as fotos estão corretas e clique em finalizar para enviar
        para o totem
      </div>
    </div>
    <div class="flex w-full overflow-x-auto py-2">
      <div
        class="m-2 containFoto inline-block flex-none relative transition"
        v-if="listaFotos.length < 1"
        @click="clickBtnToSelect"
      >
        <div
          class="inline-flex itemFoto rounded-md bg-gray-100 justify-center items-center animate-pulse"
        >
          <svg-icon
            type="mdi"
            :path="getIconPlus"
            class="text-pink-600 fill-current h-16 w-16"
          ></svg-icon>
          <div id="plus"></div>
        </div>
      </div>
      <div
        class="m-2 containFoto inline-block flex-none relative transition"
        :key="index"
        v-for="(item, index) in listaFotos"
      >
        <button
          @click="removeItem(index)"
          class="absolute right-0 bg-primary fill-current text-white p-3 -mt-2 -mr-2 rounded-md"
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.41667 3.5V2.58333C4.41667 1.57081 5.23748 0.75 6.25 0.75H11.75C12.7625 0.75 13.5833 1.57081 13.5833 2.58333V3.5H16.3333C16.8396 3.5 17.25 3.91041 17.25 4.41667C17.25 4.92293 16.8396 5.33333 16.3333 5.33333H15.4167V15.4167C15.4167 16.9355 14.1854 18.1667 12.6667 18.1667H5.33333C3.81455 18.1667 2.58333 16.9355 2.58333 15.4167V5.33333H1.66667C1.16041 5.33333 0.75 4.92293 0.75 4.41667C0.75 3.91041 1.16041 3.5 1.66667 3.5H4.41667ZM6.25 2.58333H11.75V3.5H6.25V2.58333ZM4.41667 5.33333H13.5833V15.4167C13.5833 15.9229 13.1729 16.3333 12.6667 16.3333H5.33333C4.82707 16.3333 4.41667 15.9229 4.41667 15.4167V5.33333Z"
              fill="white"
            />
          </svg>
        </button>
        <img class="inline-block itemFoto rounded-md" :src="item.src" />
      </div>
    </div>
    <div class="mx-5 font-body mb-11 font-normal text-gray-800 text-left">
      Fotos: {{ listaFotos.length }} de {{ getCompra.qtde_foto }}
    </div>
    <div>
      <input
        @change="eventChange"
        type="file"
        id="fileup"
        class="hidden"
        accept=".png,.jpeg,.jpg"
        multiple
      />

      <button
        @click="clickBtnToSelect()"
        class="btn btn-outline text-center"
        :class="{
          'bg-gray-200 text-gray-400': loadingBtn,
        }"
        :disabled="loadingBtn"
      >
        <span v-if="listaFotos.length < getCompra.qtde_foto"
          >Selecionar fotos</span
        >
        <span v-else>Selecionar outras fotos</span>
      </button>
    </div>
    <div>
      <button
        @click="uploadNow()"
        :disabled="loadingBtn || listaFotos.length < getCompra.qtde_foto"
        class="btn font-normal text-center"
        :class="{
          'border-gray-300 border border-solid bg-gray-200  text-gray-400 shadow-none':
            loadingBtn || listaFotos.length < getCompra.qtde_foto,
        }"
      >
        <div v-if="loadingBtn">
          <div class="flex justify-center items-center">
            <div
              class="animate-spin rounded-full h-10 w-10 border-b-2 border-pink-900"
            ></div>
          </div>
        </div>
        <div v-else>Enviar fotos selecionadas</div>
      </button>
    </div>
    <v-modal v-if="modalToggle">
      <div
        class="text-sm absolute right-2 top-2 inline-block"
        @click="modalToggle = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="py-1 font-normal text-left">
        {{ modalMsg }}
      </div>
    </v-modal>
  </div>
</template>

<script>
import { storageRef, firebase, db } from "@/service/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import moment from "moment";
import etapa from "@/constants/etapaCompra.js";
import { mdiImagePlus } from "@mdi/js";

export default {
  data() {
    return {
      modalMsg: "",
      modalToggle: false,
      listaFotos: [],
      files: [],
      contagem: 0,
      loadingBtn: false,
    };
  },
  mounted() {
    this.REQUEST_QRCODE();
  },
  computed: {
    getIconPlus() {
      return mdiImagePlus;
    },
    getCompra() {
      return this.$store.getters["compra/getCompra"];
    },
  },
  methods: {
    async REQUEST_QRCODE() {
      //necessario ?
      const docRef = doc(db, "compras", localStorage.getItem("compra_id"));
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.$store.commit("compra/setCompra", docSnap.data());
      } else {
        this.$router.push("/");
      }
    },
    eventChange() {
      var self = this;
      let files = document.querySelector("#fileup").files;
      function readAndPreview(file) {
        // Make sure `file.name` matches our extensions criteria
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          var reader = new FileReader();

          reader.addEventListener(
            "load",
            function () {
              self.listaFotos.push({ src: this.result });
            },
            false
          );

          reader.readAsDataURL(file);
        }
      }

      if (files) {
        [].forEach.call(files, readAndPreview);
      }
    },
    async createReferenceFirestore(path) {
      const compra_id = localStorage.getItem("compra_id");
      db.collection("fotos").add({
        url_original: path,
        url_editada: path.replace("original", "editada"),
        compra_id: compra_id,
        data: moment().format("DD/MM/YYYY"),
        horario: moment().format("hh:mm:ss"),
      });
      this.contagem += 1;
      if (this.contagem == this.listaFotos.length) {
        const docRef = doc(db, "compras", `${compra_id}`);
        await setDoc(docRef, { etapa: etapa.EDICAO }, { merge: true });
        this.$router.push(`/user/upload-sucesso`);
      }
    },
    createTaskUpload(imgBase64, index) {
      let self = this;
      let compra_id = localStorage.getItem("compra_id");
      const pathStorage =
        process.env.VUE_APP_ENV === "production"
          ? `public/${compra_id}/original/${index}`
          : `photos/${compra_id}/original/${index}`;

      this.loadingBtn = true;
      var uploadTask = storageRef
        .child(pathStorage)
        .putString(imgBase64, "data_url");

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          console.log(error);
          this.$router.push(`/user/upload-falha`);
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function () {
            self.createReferenceFirestore(pathStorage);
          });
        }
      );
    },
    uploadNow() {
      console.log("upload_start", this.getCompra);
      if (this.getCompra.qtde_foto !== this.listaFotos.length) {
        if (this.getCompra.qtde_foto > this.listaFotos.length) {
          this.modalMsg =
            "Você deve adicionar mais fotos até completar " +
            this.getCompra.qtde_foto +
            " fotos.";
          this.modalToggle = true;
        }
        if (this.getCompra.qtde_foto < this.listaFotos.length) {
          this.modalMsg =
            "Você passou do seu limite de fotos, remova até chegar a " +
            this.getCompra.qtde_foto +
            " fotos.";
          this.modalToggle = true;
        }
        return false;
      }
      if (this.$store.getters["compra/getCompra"].etapa !== "UPLOAD") {
        this.modalMsg =
          "Sem permissão para essa ação, essa compra não está nessa etapa.";
        this.modalToggle = true;
        return false;
      }
      this.listaFotos.forEach((foto, index) => {
        this.createTaskUpload(foto.src, index);
      });
    },
    clickBtnToSelect() {
      document.querySelector("#fileup").click();
    },
    removeItem(index) {
      this.listaFotos.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.containFoto {
  width: 200px;
}
.itemFoto {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.fontSize28 {
  font-size: 28px;
}
</style>
